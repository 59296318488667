import { faUser } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styled } from 'styled-components'

import { Button, FormattedMessage } from '@b-stock/bstock-react'
import { Typography } from '@b-stock/bstock-react/design-system'
import { designColors } from '@b-stock/bstock-react/theme'

import { redirectSignUp, redirectToAuth } from '@helpers/auth'

const UnloggedSectionContainer = styled.div`
  grid-area: shipment;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    margin: 0;
  }
`

const UnloggedSectionIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  color: ${designColors.neutral.veryDarkGray};
  border: 0.0625rem solid ${designColors.neutral.mediumGray};
  border-radius: 50%;
  font-size: 2rem;
`

const UnloggedSectionText = styled.p`
  ${Typography.Title2}
  color: ${designColors.neutral.black};
  && {
    margin-top: 1.5rem;
  }
`

const UnloggedSectionActions = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.75rem;
  margin-top: 3rem;
`

export const UnloggedWarning = ({
  reason,
  className,
}: {
  reason: React.ReactElement | string
  className?: string
}) => {
  return (
    <UnloggedSectionContainer
      data-testid="UnloggedWarning"
      className={className}
    >
      <UnloggedSectionIconWrapper>
        <FontAwesomeIcon icon={faUser} />
      </UnloggedSectionIconWrapper>

      <UnloggedSectionText>
        <FormattedMessage
          id="unloggedMessage.description"
          values={{ reason }}
        />
      </UnloggedSectionText>

      <UnloggedSectionActions>
        <Button onClick={redirectToAuth} size="medium" appearance="secondary">
          <FormattedMessage id={'signIn'} defaultMessage={'signIn'} />
        </Button>
        <Button onClick={redirectSignUp} size="medium" appearance="primary">
          <FormattedMessage id={'register'} defaultMessage={'register'} />
        </Button>
      </UnloggedSectionActions>
    </UnloggedSectionContainer>
  )
}

export default UnloggedWarning
